import * as Sentry from '@sentry/sveltekit';
import { version } from '$app/environment';
import { building } from '$app/environment';
import type { HandleClientError } from '@sveltejs/kit';

async function initSentry() {
	Sentry.init({
		dsn: 'https://ac55f89629c3b495c0c719c08c7e35f8@o4506626928672768.ingest.us.sentry.io/4506626929786880',
		tunnel: '/api/errors',
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		release: version,
		debug: false,
		integrations: [
			Sentry.browserProfilingIntegration(),
			Sentry.replayIntegration({
				maskAllText: true,
				blockAllMedia: true,
				mask: ['[data-mask]'],
				unmask: ['[data-unmask]'],
				maskAttributes: ['title', 'aria-label']
			})
		],
		beforeSend(event) {
			if (process.env.NODE_ENV === 'development') {
				return null;
			}

			if (event.request?.headers?.cookie) {
				delete event.request.headers.cookie;
			}

			if (event.user) {
				delete event.user.ip_address;
				delete event.user.email;
			}

			return event;
		},
		profilesSampleRate: 1.0,
		environment: process.env.NODE_ENV
	});
}

if (process.env.NODE_ENV !== 'development' && !building) {
	initSentry();
}

const errorHandler: HandleClientError = ({ error, event }) => {
    console.error(JSON.stringify({ error }, null, 2)); 
};



export const handleError =
	process.env.NODE_ENV === 'development'
		? errorHandler
		: Sentry.handleErrorWithSentry(errorHandler);
