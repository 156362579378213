import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [4],
		"/company/contact-us": [~5],
		"/company/leadership": [~6],
		"/getpaid": [~7],
		"/listings": [~8],
		"/offer.fergmar.com": [9],
		"/privacy-policy-old": [11],
		"/privacy-policy": [10],
		"/properties/for-rent": [12,[2]],
		"/properties/for-rent/[slug]": [~13,[2]],
		"/properties/for-sale": [14,[3]],
		"/properties/for-sale/[slug]": [~15,[3]],
		"/rentalAvailability": [~16],
		"/resources": [17],
		"/resources/calculators": [18],
		"/resources/calculators/mortgage-calculator": [19],
		"/services": [~20],
		"/services/investment-management": [~21],
		"/services/sell-your-property": [~22],
		"/testRoute": [~23]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';