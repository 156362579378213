import type { Reroute } from "@sveltejs/kit";

// Map SEO-friendly URLs to their canonical routes
const seoMap: Record<string, string> = {
	"/properties/for-rent/homes-for-rent": "/properties/for-rent",
};

// Map SEO-friendly URLs to their query parameters
const queryMap: Record<string, Record<string, string>> = {
	"/properties/for-rent/homes-for-rent": { type: "SingleFamily" },
};

export const reroute: Reroute = ({ url }) => {

	// Check if the URL is in our SEO mapping
	if (url.pathname in seoMap) {
		// Get the new pathname
		const newPathname = seoMap[url.pathname];

		// Get any query parameters we need to add
		const queryParams = queryMap[url.pathname];

		// If we have query parameters, add them to the URL
		if (queryParams) {
			const params = new URLSearchParams(url.searchParams);
			for (const [key, value] of Object.entries(queryParams)) {
				params.set(key, value);
			}
			url.search = params.toString();
		}

		// Return just the pathname part for routing
		return newPathname;
	}

	// Handle subdomain routing if needed
	if (url.hostname.startsWith("offer")) {
		return "/offer.fergmar.com" + url.pathname;
	}

	// No rerouting needed
	return undefined;
};
